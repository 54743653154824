<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-transparent">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Page Title-->
        <h5 class="text-dark font-weight-bold my-1 mr-5">
          {{ $t("admin.form.edit_tag_value", { name: tag.name }) }}
        </h5>
        <!--end::Page Title-->
        <b-button
          @click="
            $router.push({ name: 'TagView', params: { id: $route.params.id } })
          "
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder mr-2 text-uppercase"
        >
          <div class="d-flex">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
      </div>
    </div>
    <basic-form
      :form="form"
      :fields="fields"
      :save="save"
      :error="error"
      :errors="errors"
      :isSubmitting="isSubmitting"
      v-if="loaded"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import { insertTagValue, deleteTagValue } from "@/api/admin/stakeholders.api";
import { getTags } from "@/api/admin/stakeholders.api";

import { validText } from "@/utils/validators";
import BasicForm from "@/components/form/BasicForm.component";

export default {
  name: "TagValueCreate",
  components: {
    BasicForm
  },
  data() {
    return {
      form: {
        tagValue: "",
        tagStakeholders: []
      },
      tag: false,
      originalTagStakeholders: [],
      errors: [],
      error: false,
      isSubmitting: false,
      isSending: false,
      loading: true,
      responseCount: 0,
      totalElements: 0,
      swal: undefined
    };
  },
  computed: {
    ...mapGetters("Admin", [
      "areUsersLoaded",
      "users",
      "areDepartmentsLoaded",
      "departments"
    ]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    progress() {
      return (this.responseCount / this.totalElements || 0) * 100;
    },
    fields() {
      return [
        {
          id: "tagValue",
          required: true,
          type: "text",
          disabled: true,
          validation: validText(this.form.tagValue),
          maxLength: 255
        },
        {
          id: "tagStakeholders",
          required: true,
          type: "table_select",
          size: "12",
          options: this.tableUsers,
          fields: this.tableFields,
          perPage: 10
        }
      ];
    },
    tableUsers() {
      return _.map(this.users, user => {
        return {
          id: user.stakeholderId,
          name: user.name + " " + user.surname,
          email: user.email,
          selected: false
        };
      });
    },
    tableFields() {
      let self = this;
      return [
        {
          key: "selected",
          label: this.$t(`admin.form.selected`),
          thClass: "col-2",
          sortable: true,
          sortByFormatted: true,
          formatter(val, key, item) {
            return self.included(item);
          }
        },
        {
          key: "name",
          label: this.$t(`admin.form.name.label`),
          sortable: true,
          stickyColumn: true,
          isRowHeader: true,
          thClass: "col-5"
        },
        {
          key: "email",
          label: this.$t(`admin.form.email.label`),
          thClass: "col-5",
          sortable: true
        }
      ];
    },
    loaded() {
      return this.areUsersLoaded && !this.loading;
    }
  },
  methods: {
    ...mapActions("Admin", ["loadDepartments", "reset", "loadUsers"]),
    included(item) {
      return _.includes(this.form.tagStakeholders, item.id);
    },
    save() {
      this.isSubmitting = true;
      this.errors = [];
      this.responseCount = 0;
      this.totalElements = 0;

      let creations = _.difference(
        this.form.tagStakeholders,
        this.originalTagStakeholders
      );
      let deletions = _.difference(
        this.originalTagStakeholders,
        this.form.tagStakeholders
      );

      this.totalElements = _.size(creations) + _.size(deletions);
      if (this.totalElements > 0) {
        this.sendDataByType("creation", creations);
        this.sendDataByType("deletions", deletions);
      }
    },
    sendDataByType(type, data) {
      let sendData = undefined;
      this.responseCount = 0;

      this.swal = this.$swal({
        title: "Processing...",
        html:
          '<div id="progress-bar" style="width: 100%; background: #eee;"><div id="progress-fill" style="width: 0%; height: 10px; background: #28a745;"></div></div>',
        allowOutsideClick: false,
        showConfirmButton: false,
        onClose: () => {
          this.$router.push({
            name: "TagView",
            params: { id: this.$route.params.id }
          });
        }
      });

      if (type == "creation") {
        sendData = stakeholder => {
          return insertTagValue(this.$route.params.id, stakeholder, {
            tag: this.form.tagValue
          })
            .then(response => {
              this.responseCount++;
              // Function to update progress bar
              document.getElementById(
                "progress-fill"
              ).style.width = `${this.progress}%`;

              // (completedRequests / totalRequests) * 100;
              return response;
            })
            .catch(error => {
              return error.response;
            });
        };
      } else {
        sendData = stakeholder => {
          return deleteTagValue(this.$route.params.id, stakeholder, {
            value: this.form.tagValue
          })
            .then(response => {
              this.responseCount++;
              // Function to update progress bar
              document.getElementById(
                "progress-fill"
              ).style.width = `${this.progress}%`;

              // (completedRequests / totalRequests) * 100;
              return response;
            })
            .catch(error => {
              return error.response;
            });
        };
      }

      const limit = 25;
      const allResponses = [];

      const sendAndProcessBatch = async batch => {
        const batchRequests = batch.map(row => {
          return sendData(row);
        });

        const batchResponses = await Promise.all(batchRequests);
        allResponses.push(...batchResponses);

        if (allResponses.length === data.length) {
          _.each(allResponses, response => {
            if (response.data._meta.status === "ERROR") {
              this.$swal("Error", response.data.records, "error");
            }
          });
        }
      };

      const processAllBatches = async () => {
        const batches = _.chunk(data, limit);
        for (const batch of batches) {
          await sendAndProcessBatch(batch);
        }
      };

      processAllBatches()
        .then(() => {
          this.isSubmitting = false;
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    load() {
      this.loading = true;
      getTags(localStorage.getItem("managedCompanyId"), {
        tagId: this.$route.params.id,
        tagValue: this.$route.params.value
      }).then(response => {
        this.loading = false;
        let tag = _.first(response.data.records);
        this.tag = tag;
        this.form.tagValue = _.first(tag.values).value;
        this.form.tagStakeholders = _.first(tag.values).people;
        this.originalTagStakeholders = _.first(tag.values).people;
      });
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.areUsersLoaded) {
      this.loadUsers();
    }
    this.load();
  },
  watch: {
    responseCount(value) {
      if (value == this.totalElements) {
        this.swal?.close();
      }
    },
    isConstantsActive(value) {
      if (value === true) {
        this.loadUsers();
      }
    },
    areUsersLoaded(value) {
      if (this.isConstantsActive && value === false) {
        this.loadUsers();
      }
    }
  }
};
</script>
